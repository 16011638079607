import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import skin from './images/skin.jpg'

const About = () => {

    useEffect(() => {
        // Define scrollFunction
        const scrollFunction = () => {
          const header = document.getElementById('site-header');
          if (header) {
            if (window.scrollY > 50) {
              header.style.backgroundColor = '#000'; // Example style change
            } else {
              header.style.backgroundColor = 'transparent';
            }
          }
        };
    
        // Attach scroll event listener
        window.addEventListener('scroll', scrollFunction);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('scroll', scrollFunction);
        };
      }, []); 
    
  return (
    <>
      
      <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5">
          <div className="container">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="inner-w3-title">About Us</h2>
                <p className="inner-page-para mt-2">Exceptional Care for Your Beauty</p>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li><a to="index.html">Home</a></li>
                  <li className="active">
                    <span className="fas fa-angle-double-right mx-2"></span> About Us
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* //inner-page */}
      {/* /bottom-3-grids */}
      <div className="w3l-3-grids py-5" id="grids-3">
        <div className="container py-md-5 py-2">
          <div className="abin-top-content text-center mb-md-5 mb-4">
            <h6 className="title-subw3hny">About Us</h6>
            <h3 className="title-w3l mb-3">Our Story</h3>
            <p className="abinfo-para">
              At Cosmoderm Clinic, we are committed to enhancing your beauty through cutting-edge treatments and personalized care. Our journey began with a vision to provide top-notch aesthetic services in a tranquil and luxurious setting.
            </p>
          </div>
          <div className="aboyt-ininf">
            <div className="about-img">
              <img src={skin} alt="Cosmodermic Clinic" className="img-fluid radius-image" />
            </div>
            <div className="three-grids-hmew3">
              <div className="three-grid-1 text-left mt-5">
                <h4>Who We Are</h4>
                <p className="mt-2 pr-lg-4">
                  Cosmoderm Clinic is a premier destination for advanced skincare and beauty treatments. Our team of experts is dedicated to providing exceptional care and achieving outstanding results for every client.
                </p>
              </div>
              <div className="three-grid-1 text-left mt-5">
                <h4>What We Do</h4>
                <p className="mt-2 pr-lg-4">
                  We offer a range of services including facials, body treatments, and advanced cosmetic procedures. Each treatment is tailored to meet your individual needs and goals.
                </p>
              </div>
              <div className="three-grid-1 text-left mt-5">
                <h4>Why Choose Us</h4>
                <p className="mt-2 pr-lg-4">
                  Our clinic combines state-of-the-art technology with a relaxing atmosphere. Our team’s expertise and personalized approach ensure that you receive the highest quality care and results.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* //bottom-3-grids */}
      {/* /features-section */}
      <section className="w3l-features py-5" id="work">
  <div className="container py-lg-5 py-md-4 py-2">
    <div className="title-content text-center mb-md-5 mb-4">
      <h6 className="title-subw3hny mb-1">Why Us</h6>
      <h3 className="title-w3l mb-5">What We Offer</h3>
    </div>
    <div className="main-cont-wthree-2 align-items-center text-center pt-lg-4">
      <div className="row align-items-center">
        <div className="col-lg-3 col-md-6 mt-lg-0 mt-5">
          <div className="grids-1 box-wrap">
            <div className="icon">
              <i className="fas fa-biking"></i>
            </div>
            <h4>
            <Link to="/contact" className="title-head mb-3">Advanced Skin Treatment</Link>
            </h4>
            <p className="text-para mb-3">Experience cutting-edge skin treatments designed to rejuvenate and enhance your skin's appearance.</p>
            <Link className="w3item-link" to="/contact">
              <i className="fas fa-arrow-right"></i>
            </Link>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 mt-lg-0 mt-5">
          <div className="grids-1 box-wrap">
            <div className="icon">
              <i className="far fa-gem"></i>
            </div>
            <h4>
              <Link to="/contact" className="title-head mb-3">Laser Therapy</Link>
            </h4>
            <p className="text-para mb-3">Benefit from our state-of-the-art laser therapy for various skin conditions and cosmetic enhancements.</p>
            <Link className="w3item-link" to="/contact">
              <i className="fas fa-arrow-right"></i>
            </Link>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 mt-lg-0 mt-5">
          <div className="grids-1 box-wrap">
            <div className="icon">
              <i className="fas fa-drumstick-bite"></i>
            </div>
            <h4>
              <Link to="/contact" className="title-head mb-3">Nutritional Guidance</Link>
            </h4>
            <p className="text-para mb-3">Our experts provide personalized nutritional advice to support your skin health from within.</p>
            <Link className="w3item-link" to="/contact">
              <i className="fas fa-arrow-right"></i>
            </Link>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 mt-lg-0 mt-5">
          <div className="grids-1 box-wrap">
            <div className="icon">
              <i className="fas fa-sun"></i>
            </div>
            <h4>
              <Link to="/contact" className="title-head mb-3">Holistic Care</Link>
            </h4>
            <p className="text-para mb-3">Our holistic approach ensures comprehensive care for your skin, combining advanced treatments with natural methods.</p>
            <Link className="w3item-link" to="/contact">
              <i className="fas fa-arrow-right"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

      <footer className="w3l-footer-29 py-5">
        <div className="container py-md-4 py-3">
          <div className="footer-grid-w3ls d-flex">
            <div className="footer-left">
              <h2 className="footer-title mb-3">About Us</h2>
              <p>Cosmoderm Clinic is dedicated to providing world-class aesthetic services with a focus on quality and customer satisfaction. Our state-of-the-art facility ensures that you receive the best care in a comfortable environment.</p>
            </div>
            <div className="footer-left mx-lg-5 mx-md-4">
              <h2 className="footer-title mb-3">Quick Links</h2>
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/services">Services</Link></li>
                <li><Link to="/contact">Contact</Link></li>
              </ul>
            </div>
            <div className="footer-left">
              <h2 className="footer-title mb-3">Follow Us</h2>
              <div className="social-icons">
                <Link to="#" className="facebook"><span className="fab fa-facebook-f"></span></Link>
                <Link to="#" className="twitter"><span className="fab fa-twitter"></span></Link>
                <Link to="#" className="instagram"><span className="fab fa-instagram"></span></Link>
                <Link to="#" className="linkedin"><span className="fab fa-linkedin-in"></span></Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    
    </>
  );
};

export default About;
